import "../sass/home.scss";
import initCommon from "./common";
import homeSliders from "./utils/sliders";
import { ajaxAgenda } from "./ajax-agenda";

jQuery(function () {
  // init common functions for all pages
  initCommon();
  // init sliders home
  homeSliders();
  // init ajax agenda home
  ajaxAgenda();
});
