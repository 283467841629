import "jquery.marquee";
import LazyLoad from "vanilla-lazyload";
import MicroModal from "micromodal";

function eventFire(el, etype) {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
}

function responsiveMenu() {
  const menuButton = jQuery(".menu-button");
  const openClose = jQuery(".menu-button, .responsive-menu__overlay");
  const closeSub = jQuery(".menu-close-responsive");
  const lvl1_responsive = jQuery(
    "span.responsive-menu__item, span.responsive-menu-children__item"
  );
  openClose.on("click", function () {
    jQuery(".responsive-menu__overlay").toggleClass("active");
    jQuery(".responsive-menu").toggleClass("active");
    jQuery(".responsive-menu-children").removeClass("visible");
  });
  closeSub.on("click", function () {
    jQuery(this).parent().parent().removeClass("visible");
  });
  lvl1_responsive.on("click", function () {
    const lvl1_id = jQuery(this).data("menu");
    if (jQuery("#" + lvl1_id).hasClass("visible")) {
      jQuery("#" + lvl1_id).removeClass("visible");
    } else {
      jQuery(".children-menu").removeClass("visible");
      jQuery("#" + lvl1_id).addClass("visible");
    }
  });
}

function mainMenu() {
  const lvl1 = jQuery("span.header-menu__item, span.children-menu__item");
  const close = jQuery(".menu-close");
  close.on("click", function () {
    jQuery(".children-menu").removeClass("visible");
  });
  lvl1.on("click", function () {
    const lvl1_id = jQuery(this).data("menu");
    const lvl1_text = jQuery(this).text();
    if (jQuery("#" + lvl1_id).hasClass("visible")) {
      jQuery("#" + lvl1_id).removeClass("visible");
      jQuery("span[data-bread]").remove();
    } else {
      jQuery(".children-menu").removeClass("visible");
      jQuery("#" + lvl1_id).addClass("visible");
    }
  });

  jQuery(window).on("click", function () {
    jQuery(".children-menu").removeClass("visible");
  });

  jQuery(".children-menu, span.header-menu__item").on(
    "click",
    function (event) {
      event.stopPropagation();
    }
  );
}

export default function initCommon() {
  // load menu
  mainMenu();
  // load menu
  responsiveMenu();
  // load lazy load module
  var lazyLoad = new LazyLoad();

  //load Micromodal module
  MicroModal.init();

  jQuery(".menu-close-search").on("click", function () {
    MicroModal.close("modal-1");
  });

  // load flash Infos module
  jQuery(".flash-infos__marquee").marquee({
    duration: 20000,
    gap: 0,
    delayBeforeStart: 0,
    direction: "left",
    duplicated: false,
    pauseOnHover: true,
    startVisible: true,
  });
  if (jQuery(window).width() <= 700) {
    console.log(jQuery(window).width());
    jQuery(".footer__newsletter").insertAfter(".footer__buttons");
  }
  // access button link Orange confort
  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });

  function search() {
    const search_query = window.origin + "/?s=" + jQuery("#input-search").val();
    window.open(encodeURI(search_query));
  }

  jQuery("#je-suis").on("change", function (e) {
    console.log(jQuery(this).val());
    window.location.href = jQuery(this).val();
  });

  jQuery(".search-button-modal").on("click", function () {
    search();
  });

  jQuery("#input-search").on("keyup", function (e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      search();
    }
  });
}
