import datepicker from "js-datepicker";
import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";
const is_archive = jQuery(".post-type-archive-event").length > 0;
function ajax(params) {
  let archive_container = is_archive
    ? jQuery(".archive_posts")
    : jQuery(".slider-agenda");
  jQuery.ajax({
    url: window.origin + "/wp-admin/admin-ajax.php",
    data: params,
    type: "POST",
    beforeSend: function () {
      archive_container.html("");
      jQuery(".loader").show();
    },
    success: function (data) {
      jQuery(".loader").hide();
      archive_container.html(data);
      if (!is_archive) {
        var sliderAgenda = tns({
          container: ".slider-agenda",
          items: 1,
          loop: false,
          autoplay: false,
          autoplayButtonOutput: false,
          controls: true,
          nav: false,
          gutter: 0,
          controlsContainer: ".controls-agenda",
          responsive: {
            1280: {
              items: 2,
              slideBy: "page",
            },
          },
        });
        sliderAgenda.destroy();
        sliderAgenda = sliderAgenda.rebuild();
        console.log(sliderAgenda.getInfo());
      }
      const lazyLoad = new LazyLoad();
    },
  });
  return false;
}

export function ajaxAgenda() {
  console.log(is_archive);
  const params = {
    action: "agenda_home",
    categories: [],
    dates: "",
    date_debut: "",
    date_fin: "",
    cat: "",
    is_archive: is_archive,
  };
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
  const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Vend", "Sam"];
  const start = datepicker(".start", {
    id: 1,
    startDay: 1,
    customMonths: months,
    customDays: days,
    formatter: (input, date, instance) => {
      const value = date.toLocaleDateString("fr-FR");
      input.value = value; // => '1/1/2099'
    },
    onSelect: (instance, date) => {
      jQuery(".date_agenda").removeClass("active");
      params["date_debut"] = date.toLocaleDateString("fr-FR");
      params["dates"] = "";
      ajax(params);
    },
  });
  const end = datepicker(".end", {
    id: 1,
    startDay: 1,
    customMonths: months,
    customDays: days,
    formatter: (input, date, instance) => {
      const value = date.toLocaleDateString("fr-FR");
      input.value = value; // => '1/1/2099'
    },
    onSelect: (instance, date) => {
      jQuery(".date_agenda").removeClass("active");
      params["date_fin"] = date.toLocaleDateString("fr-FR");
      params["dates"] = "";
      ajax(params);
    },
  });
  jQuery(".date_agenda").on("click", function () {
    if (jQuery(this).hasClass("active")) {
      jQuery(".date_agenda").removeClass("active");
      params["dates"] = "";
    } else {
      jQuery(".date_agenda").removeClass("active");
      jQuery(this).addClass("active");
      params["dates"] = jQuery(this).data("date");
      params["date_debut"] = "";
      params["date_fin"] = "";
      jQuery(".start").val("");
      jQuery(".end").val("");
    }
    ajax(params);
  });
  jQuery(".moment").on("click", function () {
    if (jQuery(this).hasClass("active")) {
      jQuery(".moment").removeClass("active");
      params["cat"] = "";
    } else {
      jQuery(".moment").removeClass("active");
      jQuery(this).addClass("active");
      params["cat"] = jQuery(this).data("cat");
    }
    ajax(params);
  });

  jQuery(".select_cat").on("change", function () {
    params["categories"] = jQuery(this).val();
    jQuery(".moment").removeClass("active");
    params["cat"] = "-1";
    jQuery(".start").val("");
    jQuery(".end").val("");
    ajax(params);
  });
}
