import { tns } from "tiny-slider/src/tiny-slider";
import LazyLoad from "vanilla-lazyload";

export default function homeSliders() {
  var lazyLoad = new LazyLoad();
  function sliderLazy() {
    lazyLoad.update();
  }

  var sliderBandeau = tns({
    container: ".slider-bandeau",
    items: 1,
    autoplay: false,
    autoplayButtonOutput: false,
    controls: false,
    nav: true,
    controlsContainer: ".controls",
    navContainer: ".nav",
    responsive: {
      748: {
        nav: false,
        controls: true,
      },
    },
  });
  var sliderPub = tns({
    container: ".publications-files",
    items: 1,
    autoplay: true,
    autoplayButtonOutput: false,
    controls: true,
    nav: false,
    controlsContainer: ".controls-pub",
    responsive: {
      1280: {
        items: 2,
      },
    },
  });
  var sliderActus = tns({
    container: ".slider-actualites",
    items: 1,
    loop: false,
    autoplay: false,
    autoplayButtonOutput: false,
    controls: true,
    nav: false,
    gutter: 0,
    controlsContainer: ".controls-actualites",
    center: false,
    responsive: {
      1280: {
        items: 2,
        center: true,
        gutter: 300,
      },
    },
  });
  var sliderAgenda = tns({
    container: ".slider-agenda",
    items: 1,
    loop: false,
    autoplay: false,
    autoplayButtonOutput: false,
    controls: true,
    nav: false,
    gutter: 0,
    controlsContainer: ".controls-agenda",
    responsive: {
      1280: {
        items: 2,
        slideBy: "page",
      },
    },
  });
  sliderPub.events.on("transitionEnd", sliderLazy);
  sliderBandeau.events.on("transitionEnd", sliderLazy);
  sliderAgenda.events.on("transitionEnd", sliderLazy);
  sliderActus.events.on("transitionEnd", sliderLazy);
}
